import { Grid } from 'common/layouts/grid/Grid';
import { GridColumn } from 'common/layouts/grid/GridColumn';
import {
  ButtonColor,
  PrimaryButton,
} from 'component-library/components/buttons/PrimaryButton';
import { LinkWrapper } from 'component-library/components/links/LinkWrapper';
import { Heading } from 'component-library/components/typography/Heading';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import styled from 'styled-components';

import { StyledUl, WelcomeWrapper } from './welcomeUtils';

const StyledGrid = styled(Grid)`
  align-items: center;
`;

const Image = styled(GridColumn)`
  display: flex;
  justify-content: center;
`;

const Body = styled(ResponsiveBody)`
  display: inline;
`;

interface WelcomeHeroContainerProps {}

export const WelcomeHeroContainer: FC<WelcomeHeroContainerProps> = () => (
  <WelcomeWrapper>
    <StyledGrid cols={2} gap={3} desktopGap={5}>
      <GridColumn span={2} desktopSpan={1}>
        <Heading marginBottom={16} variant={4}>
          See how you can spend retirement
        </Heading>
        <ResponsiveBody marginBottom={32} variant={3} maxWidth={540}>
          Whether you have $50,000 or $500,000, we&apos;ll help you spend your
          time and money confidently in retirement.
        </ResponsiveBody>
        <Heading marginBottom={16} variant={5}>
          Schedule a free consultation to see:
        </Heading>
        <StyledUl>
          <li>
            <Body variant={3}>Your monthly retirement income</Body>
          </li>
          <li>
            <Body variant={3}>How to make your savings last</Body>
          </li>
          <li>
            <Body variant={3}>When you should elect Social Security</Body>
          </li>
        </StyledUl>
        <LinkWrapper to="/welcome/sign-up">
          <PrimaryButton
            marginTop={40}
            buttonColor={ButtonColor.Peach}
            withCaret
          >
            Let&apos;s Begin
          </PrimaryButton>
        </LinkWrapper>
      </GridColumn>
      <Image span={2} desktopSpan={1}>
        <StaticImage
          width={500}
          src="./welcome-hero.png"
          alt="couple-smiling"
        />
      </Image>
    </StyledGrid>
  </WelcomeWrapper>
);
