import { device } from 'shared/theme';
import styled from 'styled-components';

export const WelcomeWrapper = styled.div<{ maxWidth?: number }>`
  padding 16px;

  ${`@media ${device.lg}`} {
    max-width: ${({ maxWidth = 1560 }) => `${maxWidth}px`};
    padding: 80px 40px;
    margin: 0px auto;
  }
`;

export const StyledUl = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 16px;
`;
