import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { colors } from 'component-library/styles/colors';
import { SEO } from 'components/SEO';
import { Footer, FooterTemplate } from 'page-components/shared/footer';
import { WelcomeHeroContainer } from 'page-components/welcome/WelcomeHeroContainer';
import React, { FC, useEffect } from 'react';
import { adConversionEvents, track } from 'utils/analytics';

interface WelcomeProps {}

const Welcome: FC<WelcomeProps> = () => {
  useEffect(() => {
    track('Signup step', {
      step: 'Welcome',
    });
    track(adConversionEvents.funnelStarted);
  }, []);

  return (
    <Layout>
      <Navigation simpleNav />
      <PageWrapper background={colors.greenLight}>
        <WelcomeHeroContainer />
        <Footer template={FooterTemplate.LegalDisclaimerOnly} />
      </PageWrapper>
    </Layout>
  );
};

export default Welcome;

export const Head = () => (
  <SEO
    title="Welcome to Retirable | Retirable"
    description="Welcome to Retirable. The first-of-its-kind planning, investing and spending solution built for retirees to make the most out of their golden years."
    imagePath="welcome-featured.png"
  />
);
